

.Week-picker > * {
    text-align: center;
}

.Week-picker .Icon {
    width: 50%;
    height: 100%;
}
.Event-list-item{
    --ion-item-background:linear-gradient(to right, #6190E8 0%, #6190E8 51%, #6190E8 100%);
}
.Event-list-item ion-item {
    color: white;
    padding: 5px 10px; 
}
.Event-list-item ion-item::part(native) {
    border-radius: 10px;
    height: 60px;
}
.btn-white {
    --background: white;
    --color: black;
    width: 50%;
    margin: 1rem auto 1rem auto;
}


