
.Login-form {
    text-align: center;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(7,1,19,1) 0%, rgba(45,215,253,1) 0%, rgba(45,25,25,1) 0%, rgba(45,92,106,1) 33%, rgba(17,16,16,1) 56%);
    height: 100%;
    position: fixed;
}

.Login-form .ion-content {
    --ion-background-color:#111D12;
}
.Login-form input {
    background-color: rgb(230, 225, 225);
    margin: .5rem;
    border-radius: 10px;
    border: 1px solid #07A2D7;
    height: 30px;
    padding: 20px;
}
.login-btn {
    --background: #07A2D7 !important;
    --border-radius: 10px;
}