
.Comment-container {
    width: 100%;
}
.Comment-container p {
    color: #fff;
}

.Comment-image-container {
    position: relative;
    width: 100%;
    height: 370px;
    text-align: center;
}

.Comment-image-container > img {
    max-height: 100%;
    margin: auto;
}

.Comment-image-container > div {
    position: absolute;
    z-index: 100;
    align-items: center;
    color: #fff;
    display: flex;
}

.Comment-image-container .Back-btn {
    left: 0;
    top: 0;
    bottom: 0;
}

.Comment-image-container .Forward-btn {

    right: 0;
    top: 0;
    bottom: 0;
}