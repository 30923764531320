.logout-btn {
    --background: #fff;
    color: #fff;
    margin-right: 1rem;
}
.comment-list, .list-md {
    background: #6190E8;
}
.comment-list .item{
    --background: #6190E8;
}

.input .item-has-focus {
    --highlight-background: #fff;
}
.item-has-focus{
    --color: #fff !important;
   --highlight-background: #fff;
  }
.item-has-focus > ion-label{
    color: #fff !important;
  }
ion-back-button{
    --color: white !important;
}
ion-toolbar {
    --background: #6190E8 !important;
}
ion-footer {
    position: absolute;
    bottom: 0;
}